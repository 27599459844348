import React from "react";
import { StyledLink } from "./styled-link";
import PropTypes from "prop-types";

export function FooterItem({ href, children }) {
  return (
    <li>
      <StyledLink href={href} target="_blank" rel="noreferrer">
        {children}
      </StyledLink>
    </li>
  );
}

FooterItem.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
};
