import React, { useState } from "react";
import styled from "styled-components";
import { FooterItem } from "./footer-item";

export function Footer() {
  const [embedHref, setEmbedHref] = useState(false);

  return (
    <FooterMenu>
      <ul>
        <div
          onMouseEnter={() => setEmbedHref(true)}
          onMouseLeave={() => setEmbedHref(false)}
        >
          <FooterItem
            href={embedHref ? "mailto:hello@childrenofthevortex.com" : ""}
          >
            touch
          </FooterItem>
        </div>
        <FooterItem href="https://soundcloud.com/childrenofthevortex">
          volatility
        </FooterItem>
        <FooterItem href="https://childrenofthevortex.bandcamp.com/">
          trea$ury
        </FooterItem>
        <FooterItem href="https://www.facebook.com/childrenofthevortex/">
          conceit
        </FooterItem>
      </ul>
    </FooterMenu>
  );
}

const FooterMenu = styled.div`
  margin-top: auto;

  ul {
    display: flex;
    flex-direction: row;
    max-width: 430px;
    justify-content: space-between;
    li {
      list-style-type: none;
    }
  }
`;
