import React from "react";
import styled from "styled-components";
import logo from "./assets/logo.png";
import { Catalogue } from "./components/catalogue";
import { Footer } from "./components/footer";
import { TextScroll } from "./components/text-scroll";

const Layout = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 50px;

  img {
    max-width: 150px;
    width: 100%;
    height: auto;
    order: -1;
    margin-bottom: 30px;
    align-self: center;
  }

  h1 {
    font-size: 45px;
  }

  @media (min-width: 891px) {
    flex-direction: row;

    h1 {
      font-size: 55px;
    }

    img {
      order: 0;
    }
  }

  img:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(0.2px, 0.1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(0.2px, 0.4px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-0.1px, 0.4px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 0.2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
`;

export default function App() {
  return (
    <>
      <TextScroll>
        ◊◊◊ new pablo frizzi album "soul science" out now on cassette/digital !!! ◊◊◊
      </TextScroll>
      <Layout>
        <Header>
          <div>
            <h1>
              <i>Children Of The Vortex</i>
            </h1>
            <span>Record label</span>
          </div>
          <img src={logo} alt="Logo" style={{ width: 200 }} />
        </Header>
        <Catalogue records={records} />
        <Footer />
      </Layout>
    </>
  );
}

const records = [
  {
    artist: "Paweł Galecki",
    title: "Down Below",
    url: "https://childrenofthevortex.bandcamp.com/album/down-below",
    year: 2021,
  },
  {
    artist: "Pablo Frizzi",
    title: "Soul Science",
    url: "https://childrenofthevortex.bandcamp.com/album/soul-science",
    year: 2022,
  },
];
