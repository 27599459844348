import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export function Record({ year, artist, title, url }) {
  console.log(year);
  return (
    <div style={{ display: "inline-block" }}>
      <StyledLink href={url || ""} target="_blank" rel="noreferrer">
        {artist} <i>"{title}"</i> ({year})
      </StyledLink>
    </div>
  );
}

const StyledLink = styled.a`
  text-decoration: none;
  color: black;
  &:focus,
  &:active {
    color: black;
  }
  &:hover {
    color: #f012be;
  }

  transition: color 0.2s;
`;

Record.propTypes = {
  catalogueNumber: PropTypes.string,
  artist: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};
