import React from "react";
import styled from "styled-components";

export function TextScroll({ children }) {
  return (
    <Scroll>
      <div className="scroll-container">
        <div className="scroll-text">{children}</div>
      </div>
    </Scroll>
  );
}

export const Scroll = styled.div`
  .scroll-container {
    border: 3px solid black;
    /* border-radius: 5px; */
    overflow: hidden;
    background-color: black;
  }

  .scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 30s linear infinite;
    -webkit-animation: my-animation 30s linear infinite;
    animation: my-animation 30s linear infinite;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
`;
