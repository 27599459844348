import React from "react";
import { Record } from "./record";
import PropTypes from "prop-types";
import styled from "styled-components";

export function Catalogue({ records }) {
  return (
    <CatalogueWrapper>
      <h3>Catalogue:</h3>
      {records.map(({ year, artist, title, url }, index) => {
        return (
          <Record
            year={year}
            artist={artist}
            title={title}
            key={index}
            url={url}
          />
        );
      })}
    </CatalogueWrapper>
  );
}

const CatalogueWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 5px;
  }
`;

Catalogue.propTypes = {
  records: PropTypes.array,
};
