import styled from "styled-components";

export const StyledLink = styled.a`
  text-decoration: none;
  color: black;
  &:focus,
  &:active {
    color: black;
  }
  &:hover {
    color: #f012be;
  }

  transition: color 0.1s;
`;
